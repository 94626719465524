<script setup>
  import Rating from '@/views/order/compontent/rating'
  import { getCurrentInstance, onMounted, ref, reactive, nextTick, defineEmits, inject } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const getOrderDetail = inject('getOrderDetail')

  const props = defineProps({
    subOrderNo: {
      type: String
    }
  })

  const targetElement = ref(null)

  const data = reactive({
    loading: false,
    markData: {
      level: 0, // 等级
      deliverLevel: 0, // 物流等级
      serviceLevel: 0, // 服务等级
      content: '', // 评论内容
      images: [], // 评论图
      anonymousUser: true, // 是否匿名
      orderNo: ''
    },
    hintText: ''
  })

  // 上传图片
  const uploadImg = (list) => {
    console.log(list, 'list')
    data.markData.images = list
  }

  // 上传评论
  const addProductEvaluate = () => {
    if (data.loading) return
    data.loading = true
    api.product
      .commentRecords(data.markData)
      .then((res) => {
        console.log('res', res)
        getOrderDetail()
      })
      .finally(() => {
        data.loading = false
      })
  }

  const submitMark = async () => {
    data.hintText = ``
    if (data.markData.level === 0 || data.markData.deliverLevel === 0 || data.markData.serviceLevel === 0) {
      data.hintText = `Please complete the rating.`
      await nextTick(() => {
        targetElement.value.scrollIntoView({ behavior: 'smooth' })
      })
      return
    }
    addProductEvaluate()
    console.log(data.markData, 'submitMark提交评论内容')
  }

  onMounted(() => {
    data.markData.orderNo = props.subOrderNo
  })
</script>

<template>
  <div class="review-box" v-loading="data.loading">
    <div class="title" ref="targetElement">Review</div>
    <div class="rowBC">
      <div class="rowSC">
        <div class="lable">Quality</div>
        <Rating v-model:curRating="data.markData.level" />
      </div>
      <div class="rowSC">
        <div class="lable">Delivery</div>
        <Rating v-model:curRating="data.markData.deliverLevel" />
      </div>
      <div class="rowSC">
        <div class="lable">Service</div>
        <Rating v-model:curRating="data.markData.serviceLevel" />
      </div>
    </div>

    <div class="review-content-box">
      <el-input
        type="textarea"
        class="review-content"
        v-model="data.markData.content"
        placeholder="Don’t be shy, tell us more !"
        maxlength="250"
        :autosize="{ minRows: 8, maxRows: 15 }"
        @blur="data.markData.content = $event.target.value.trim()"
        clearable
      />
      <div class="review-image">
        <div class="image-content rowBE">
          <img-upload :fileCount="6" :imgs="data.markData.images ? data.markData.images : []" @imgsCallback="uploadImg"></img-upload>
          <div class="hint-box">
            <div>Text: {{ data.markData.content.length }} / 250</div>
            <div>Image: {{ data.markData.images.length }} / 6</div>
          </div>
        </div>
      </div>
    </div>
    <div class="rowBS">
      <div class="mt-1">
        <p class="hint-text" v-if="data.hintText">{{ data.hintText }}</p>
        <el-switch v-model="data.markData.anonymousUser" active-text="Anonymous Review" />
      </div>
      <div class="sub-btn" @click="submitMark">Submit</div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .review-box {
    padding: 10px 0;
    .title {
      color: #222;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .lable {
      color: #222;
      font-size: 14px;
      margin-right: 5px;
    }
    .review-content-box {
      position: relative;
      .review-image {
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        .image-content {
          width: 100%;
          height: 100px;
          padding: 0 10px;
        }
      }
      .hint-box {
        min-width: 100px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: end;
      }
    }
    .review-content {
      margin-top: 10px;
    }
  }

  .sub-btn {
    padding: 8px 24px;
    margin: 40px 0 0 0;
    font-size: 16px;
    background: var(--btnBgColor);
    color: #fff;
    border-radius: 24px;
    cursor: pointer;
  }

  .hint-text {
    color: var(--el-color-danger);
  }
</style>

<script setup>
  import reviewBox from './review-box'
  import reviewItem from '@/views/product/details/compontent/review-item'
  import { defineComponent, getCurrentInstance, onMounted, ref, reactive, toRefs, watchEffect } from 'vue'
  const props = defineProps({
    list: {
      type: Array
    },
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })
  const data = reactive({
    obj: '2312312',
    ceshi: [1, 2, 3, 4, 5],
    activeNames: 0
  })

  const runcateString = (str, maxLength) => {
    if (!str) return ''
    if (str.length <= maxLength) {
      return `${str}`
    }
    return `${str.substring(0, maxLength)} ...`
  }
</script>

<template>
  <div class="order_main_card">
    <div class="table_title">
      <div class="item Information">{{ $t('Product Information') }}</div>
      <div class="rowSS">
        <div class="item Total">{{ $t('Unit Price') }}</div>
        <div class="item Quantity">{{ $t('Quantity') }}</div>
        <div class="item Total">{{ $t('Total') }}</div>
      </div>
    </div>

    <el-collapse v-model="data.activeNames" accordion>
      <template v-for="(product, index) in props.orderInfo.subOrders" :key="index">
        <template v-for="(spec, specIndex) in product.subOrderSpecs" :key="specIndex">
          <el-collapse-item :title="runcateString(product.productName, 80)" :name="index" class="table-item">
            <div class="pro_item rowBC">
              <div class="pro_main rowSC">
                <div class="pro_img">
                  <JLImg :src="spec.specCoverImg" />
                </div>
                <div class="pro_info columnCC">
                  <div class="pro_sx">
                    <span>{{ spec.specName }}</span>
                  </div>
                </div>
              </div>

              <div class="rowSC">
                <div class="Total">{{ JLTools.format_number(spec.amount) }}</div>
                <div class="Quantity">× {{ spec.purchaseNum }}</div>
                <div class="Total">{{ JLTools.forPrice(product.paidAmount) }}</div>
              </div>
            </div>
            <template v-if="product.list && product.list.length">
              <div v-for="(item, index) in product.list" :key="index" class="review-item">
                <review-item :reviewItem="item"></review-item>
              </div>
            </template>
            <template v-else>
              <reviewBox :subOrderNo="product.subOrderNo" />
            </template>
          </el-collapse-item>
        </template>
      </template>
    </el-collapse>

    <div class="table_footer" data-remark="总订单合计">
      <div class="settlement_right_item">
        <span class="key mr10">{{ $t('Subtotal') }}:</span>
        <b class="value">{{ JLTools.format_number(props.orderInfo.totalAmount) }}</b>
      </div>
      <div class="settlement_right_item">
        <span class="key mr10">{{ $t('Shipping fee') }}:</span>
        <b class="value">{{ JLTools.format_number(props.orderInfo.totalLogisticAmount) }}</b>
      </div>
      <div class="settlement_right_item">
        <span class="key mr10">{{ $t('Discount') }}:</span>
        <b class="value totle">{{ JLTools.format_number(props.orderInfo.totalFavourableAmount) }}</b>
      </div>
      <div class="settlement_right_item">
        <span class="key mr10">Paid Amount:</span>
        <b class="value totle">{{ JLTools.format_number(props.orderInfo.totalPaidAmount) }}</b>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
  .order_main_card {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 15px #eee;

    .table-item {
      width: 100%;
      height: 100%;
      padding: 0 15px;
      border-bottom: 1px solid #e1e1e1;
    }
  }

  .order_main_card .table_title {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    line-height: 50px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  .order_main_card .table_title .item {
    text-align: center;
  }

  .order_main_card .table_title .item.Information {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
  }

  .order_main_card .table_title .item.Number {
    width: 200px;
  }

  .order_main_card .table_title .item.Quantity {
    width: 120px;
  }

  .order_main_card .table_title .item.Total {
    width: 120px;
  }

  .order_main_card .table_title .item.Operation {
    width: 118px;
  }

  .order_main_card .pro_item {
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    .Quantity {
      width: 120px;
      line-height: 90px;
      text-align: center;
    }
    .Total {
      width: 120px;
      line-height: 90px;
      text-align: center;
    }
  }

  .order_main_card .pro_item .pro_main {
    max-width: 680px;
    height: 100px;
    padding: 15px;
    .pro_img {
      width: 70px;
      height: 70px;
      cursor: pointer;
    }
    .pro_info {
      width: fit-content;
      height: 70px;
      padding-left: 15px;
      .pro_name {
        cursor: pointer;
      }
      .pro_sx {
        width: 100%;
        margin-top: 10px;
        color: #999;
      }
    }
  }

  .order_main_card .pro_item .Number {
    width: 200px;
    line-height: 90px;
    text-align: center;
    float: right;
  }

  .order_main_card .pro_item .Operation {
    width: 118px;
    line-height: 90px;
    text-align: center;
  }

  .order_main_card .table_body {
    width: 100%;
  }

  .order_main_card .table_footer {
    display: flex;
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    justify-content: flex-end;

    .settlement_right_item {
      margin-left: 30px;
    }
  }

  .review-item {
    width: 800px;
    margin-top: 20px;
  }
</style>

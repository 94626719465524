<template>
  <div class="rating flex_center">
    <!-- @mouseover="mouseoverRating(n)" -->
    <span v-for="n in parseInt(props.rating)" :key="n" @click="setRating(n)">
      <i class="iconfont wujiaoxing" :class="['icon-kongxinwujiaoxing', getStarClass(n)]"></i>
    </span>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive, defineProps, defineEmits } from 'vue'

  const props = defineProps({
    rating: {
      // 总共多少颗星星
      type: Number,
      default: 5
    },
    curRating: {
      // 传入分数
      type: Number,
      default: 0
    }
  })

  const $emit = defineEmits(['update:curRating'])

  const data = reactive({
    hoverRating: 0, // 当前分数
    isChange: false
  })

  const setRating = (value) => {
    if (data.isChange) return
    data.hoverRating = value
    $emit('update:curRating', data.hoverRating)
    // console.log('当前分数', data.hoverRating)
  }

  const mouseoverRating = (value) => {
    if (data.isChange) return
    data.hoverRating = value
    $emit('update:curRating', data.hoverRating)
    // console.log('当前分数', data.hoverRating)
  }

  const getStarClass = (index) => {
    if (data.hoverRating >= index) {
      // 满星
      return 'icon-shixinwujiaoxing'
    } else if (data.hoverRating + 0.5 >= index) {
      // 半星
      return 'fa-star-half-alt'
    } 
      // 未选中
      return 'icon-kongxinwujiaoxing'
    
  }

  onMounted(() => {
    data.isChange = props.curRating > 0
    data.hoverRating = props.curRating
  })
</script>

<style>
  /* Add styles for stars here */

  .wujiaoxing {
    font-size: 14px;
    /* margin-right: 5px; */
  }

  .icon-shixinwujiaoxing {
    color: #F7BA2A !important;
  }

  .icon-kongxinwujiaoxing {
    color: #D2D2D3;
  }
</style>

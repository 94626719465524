<template>
  <div class="page">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Page"
}
</script>

<style lang="less">
.page {
  height: 100%;
  width: var(--minWidth);
  margin: 0 auto;
}
</style>
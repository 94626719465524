<!-- 收货评论页面 -->
<script setup>
  import Page from '@/components/Page'
  import orderList from './compontent/order-list'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, provide } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const targetElement = ref(null)
  const data = reactive({
    loading: false,
    orderId: '', // 订单id
    orderInfo: {} // 订单信息
  })

  // 查询订单详情
  const getOrderDetail = () => {
    data.loading = true
    api.order
      .ordersDetail({
        mainOrderNo: data.orderId,
        commentRecord: true
      })
      .then((res) => {
        console.log('订单详情', res)
        if (res && res.subOrders) {
          let subOrderSpecs = []
          data.orderInfo = res
          data.addressInfo = res.subOrders[0]
          // console.log(data.orderInfo, 'data.orderInfo')
        }
      })
      .catch((error) => {
        proxy.$toast(error)
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 初始化
  const init = () => {
    if (JLTools.isNull(data.orderId)) return
    getOrderDetail()
  }

  onMounted(() => {
    console.info(useRoute(), '312312')
    data.orderId = useRoute().query.orderId || ''
    init()
  })

  provide('getOrderDetail', getOrderDetail)
</script>

<template>
  <Page>
    <div class="page-content">
      <div class="pro-main-breadcrumb rowSC">
        <span @click="toUserInfo">{{ $t('Home') }}</span>
        <img src="~img/down_icon.svg" />
        <span @click="toOrderMain">{{ $t('Orders') }}</span>
        <img src="~img/down_icon.svg" />
        <b>{{ $t('Order Number') }}:{{ data.orderId }}</b>
      </div>
      <!-- 订单信息 -->
      <div class="order-info-card" data-remark="总订单商品列表">
        <order-list :orderInfo="data.orderInfo" />
      </div>
    </div>
  </Page>
</template>

<style lang="less" scoped>
  .page-content {
    padding: 0 20px;
    min-height: calc(100vh - 155px - 170px);
  }

  .pro-main-breadcrumb {
    width: 100%;
    padding: 12px 0;
    font-size: 13px;
    line-height: 30px;

    img {
      margin: 9px;
      width: 12px;
      height: 12px;
      transform: rotate(270deg);
    }
  }
</style>
